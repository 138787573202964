import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, timer } from 'rxjs';
import { AdamConf } from '@app/app.config';
import { AuthService } from '@app/auth/auth.service';
import { LoggerService } from '@app/core/logger.service';
import { MaterialsManagementService } from '../materials-management.service';
import { StorageService } from 'src/app/core/storage.service';
import {
  FilterStateConfig,
  MagnifiedViewModel,
  TileDataRequestParams,
  TileResponseData,
  DataPointsInfo,
} from '../../models/materials-management-dashboard.model';
import { ChartDataItem, ChartConfig, BarConfig } from '@app/shared/models/grouped-bar-chart.model';
import { GroupedBarChartComponent } from 'src/app/shared/components/grouped-bar-chart/grouped-bar-chart.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-core-dashboard-materials-management-boxes-ordered-replenished-tile',
  templateUrl: './boxes-ordered-replenished-tile.component.html',
  styleUrls: ['./boxes-ordered-replenished-tile.component.scss'],
  standalone: true,
  imports: [GroupedBarChartComponent, CommonModule]
})
export class BoxesOrderedReplenishedTileComponent implements OnInit, OnDestroy {

  public adamLabels = AdamConf;
  public ticker$: Subscription;
  private boxesOrderedReplenishedFilterSubscription$: Subscription;
  private boxesOrderedReplenishedDataSubscription$: Subscription;
  public boxesOrderedReplenishedData: TileResponseData;
  @Input() showMagnifiedViewPopupData: MagnifiedViewModel;
  @Output() showMagnifiedPopup = new EventEmitter<MagnifiedViewModel>();
  public barConfig: BarConfig = {
    bar1: '#888b8d',
    bar2: '#12224D'
  };
  public chartMetaData: ChartConfig = {
    margin: {
      top: 40,
      right: 5,
      bottom: 30,
      left: 32
    }
  };
  public barChartData: ChartDataItem[];
  public bar1Items: DataPointsInfo[];
  public bar2Items: DataPointsInfo[];
  public isDisplayFilter = false;
  public selectedOptions = [];
  public filterConfig: FilterStateConfig;

  constructor(
    private readonly router: Router,
    private readonly logger: LoggerService,
    private readonly authService: AuthService,
    private readonly materialManagementService: MaterialsManagementService,
    private readonly storageService: StorageService,
    private readonly cdr: ChangeDetectorRef

  ) { }

  public ngOnInit(): void {
    this.barChartData = [];
    this.bar1Items = [];
    this.bar2Items = [];
    this.boxesReplenishedData();
    this.setAutoRefresh();
    this.cdr.detectChanges();
  }

  private boxesReplenishedData(): void {
    this.resetInventorySubscriptions();
    this.boxesOrderedReplenishedFilterSubscription$ =
      this.materialManagementService.boxesOrderedReplenishedTileFilterState$.subscribe((filterState) => {
        if (filterState) {
          let tileDataRequestParams: TileDataRequestParams;

          if (this.boxesOrderedReplenishedDataSubscription$) {
            this.boxesOrderedReplenishedDataSubscription$.unsubscribe();
          }
          tileDataRequestParams = {
            tileName: this.adamLabels.materialManagement_module.boxesOrderedReplenishedTileName,
            tileFilters: filterState
          };
          if (tileDataRequestParams && tileDataRequestParams.tileFilters.length > 0) {
            const selectedFilters = tileDataRequestParams.tileFilters.filter(item =>
              item.selectedValues && item.selectedValues.length > 0);
            this.storageService.setItem('boxTileFilter', JSON.stringify(tileDataRequestParams.tileFilters));
            this.selectedOptions = [];
            selectedFilters.forEach(item => {
              if (item.selectedValues.length > 0  && item.fieldName !== this.adamLabels.materialManagement_module.customDateRange) {
                item.selectedValues.forEach(ele => this.selectedOptions.push(ele));
              }
            });

          }
          this.boxesOrderedReplenishedDataSubscription$ =
            this.materialManagementService.getTileData(tileDataRequestParams)
              .subscribe((response) => {
                if (response.responseCode === '2001' && response.responseData) {
                  this.boxesOrderedReplenishedData = response.responseData;
                  if (response.responseData.dataSeriesCollection) {
                    this.bar1Items = this.getDataToDrawGraph(response.responseData, this.adamLabels.materialManagement_module.bar1Label);
                    this.bar2Items = this.getDataToDrawGraph(response.responseData, this.adamLabels.materialManagement_module.bar2Label);
                  }
                  this.setChartData(response.responseData);
                  this.cdr.detectChanges();
                } else {
                  this.logger.log('Invalid Tile Data response received for Inventory Stack');
                  this.cdr.detectChanges();
                }
              }, (error) => {
                this.logger.log('Invalid Tile Data response received for Inventory Stack');
                this.cdr.detectChanges();
              });
        }
      });
  }

  private setAutoRefresh(): void {
    const currentUser = this.authService.getCurrentUser();
    if (currentUser.isAutoRefreshEnabled === true && this.showMagnifiedViewPopupData.isMagnifiedView !== true) {
      const delayToStartTimer = currentUser.autoRefreshTimeInterval;
      const timerInterval = delayToStartTimer;
      const source = timer(delayToStartTimer, timerInterval);
      this.ticker$ = source.subscribe((val) => {
        this.logger.log('Refreshing Boxes ordered vs replenished tile data');
        this.boxesReplenishedData();
        this.cdr.detectChanges();
      });
    }
  }

  private resetInventorySubscriptions(): void {
    if (this.boxesOrderedReplenishedFilterSubscription$) {
      this.boxesOrderedReplenishedFilterSubscription$.unsubscribe();
    }
    if (this.boxesOrderedReplenishedDataSubscription$) {
      this.boxesOrderedReplenishedDataSubscription$.unsubscribe();
    }
  }

  public ngOnDestroy(): void {
    this.resetInventorySubscriptions();
    if (this.ticker$) {
      this.ticker$.unsubscribe();
    }
  }

  private getDataToDrawGraph(data: TileResponseData, axes: string): DataPointsInfo[] {
    let dataItems = [];
    const item0 = 0;
    const item1 = 1;
    switch (axes) {
      case data.dataSeriesCollection[item0].legend: {
        dataItems = data.dataSeriesCollection[item0].dataPoints;
        break;
      }
      case data.dataSeriesCollection[item1].legend: {
        dataItems = data.dataSeriesCollection[item1].dataPoints;
        break;
      }

    }
    return dataItems;
  }

  private setChartData(resp: TileResponseData): void {
    if (resp.categories && resp.categories.length > 0
      && this.bar1Items.length > 0 && this.bar2Items.length) {
      let tempArr = [];
      for (let i = 0; i < resp.categories.length; i++) {
        const tempChartItem = {
          category: resp.categories[i],
          barChartData: [{
            value: this.bar1Items[i].value,
            label: 'bar1',
            toolTipInfo: this.bar1Items[i].toolTipInfo,
          },
          {
            value: this.bar2Items[i].value,
            label: 'bar2',
            toolTipInfo: this.bar2Items[i].toolTipInfo,
          }]
        };

        tempArr.push(tempChartItem);
      }
      this.barChartData = tempArr;
      this.cdr.detectChanges();
    }
  }

  public displayFilter(): void {
    this.isDisplayFilter = true;
    this.filterConfig = {
      isDisplayFilter: this.isDisplayFilter,
      tile: this.adamLabels.materialManagement_module.boxesOrderedReplenishedTileName
    };
    this.materialManagementService.setFilterPopupState(this.filterConfig);
    this.showMagnifiedViewPopupData.isMagnifiedView = false;
    this.cdr.detectChanges();
  }

  public showMagnifiedView(): void {
    this.showMagnifiedViewPopupData.isMagnifiedView = (this.showMagnifiedViewPopupData.isMagnifiedView === true) ? false : true;
    this.showMagnifiedViewPopupData.tile = (this.showMagnifiedViewPopupData.isMagnifiedView === true) ?
      this.adamLabels.materialManagement_module.boxesOrderedReplenishedTileName : '';
    this.showMagnifiedPopup.emit(this.showMagnifiedViewPopupData);
    this.cdr.detectChanges();
  }

  public navigateToBoxesOrderedOverview(): void {
    this.router.navigate(['/control-tower/boxes-ordered-vs-replenished']);
    this.cdr.detectChanges();
  }

}
